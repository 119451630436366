import { useState, useEffect } from 'react';
import { toast } from 'sonner';

export function useWalletBalance(userAddress) {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      if (!userAddress) return;

      try {
        const response = await fetch(`https://toncenter.com/api/v3/account?address=${userAddress}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch balance');
        }

        const data = await response.json();
        const balanceInTON = data?.balance ? Number(data.balance) / 1e9 : 0;
        setBalance(balanceInTON);
      } catch (error) {
        console.error('Error fetching balance:', error);
        toast.error('Failed to fetch wallet balance');
        setBalance(0);
      }
    };

    fetchBalance();
    const interval = setInterval(fetchBalance, 30000);

    return () => clearInterval(interval);
  }, [userAddress]);

  return balance;
}