import React from 'react';
import { useWalletAuth } from "../WalletAuthContext";
import { useLeaderDashboard } from "../hooks/useLeaderDashboard";
import CabalControlPanel from '../components/CabalControlPanel';
import AsciiNoiseBackground from '../components/AsciiNoiseBackground';

const CabalLeaderPanel = () => {
  const { userAddress } = useWalletAuth();
  const { data: dashboardData, isLoading } = useLeaderDashboard(userAddress);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4">
        <div className="flex items-center justify-center h-full">
          Loading dashboard data...
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <AsciiNoiseBackground />
      <div className="relative z-10">
        <CabalControlPanel cabalData={dashboardData?.cabal} />
      </div>
    </div>
  );
};

export default CabalLeaderPanel;