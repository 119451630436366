import React, { createContext, useContext, useState, useEffect } from "react";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { useCookies } from "react-cookie";
import { tonProofService } from "./services/TonProofService";
import { supabase } from "./lib/supabase";
import { toast } from "sonner";
import { toRawAddress } from "./utils/addressUtils";

const WalletAuthContext = createContext(null);

export function WalletAuthProvider({ children }) {
  const [tonConnectUI] = useTonConnectUI();
  const userAddress = useTonAddress();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [cookies, setCookie] = useCookies(['cabaltoken']);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const rawAddress = userAddress ? toRawAddress(userAddress) : null;

  async function checkProof(wallet, tonProof) {
    setIsLoading(true);
    const account = wallet.account;
    const rawAddress = toRawAddress(account.address);

    try {
      const { data: verifyResult, error: verifyError } = await supabase.functions.invoke(
        'verify-ton-proof',
        {
          body: {
            proof: {
              ...tonProof.proof,
              state_init: wallet.account.walletStateInit
            },
            address: rawAddress,
            publicKey: wallet.account.publicKey
          },
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (verifyError) {
        throw verifyError;
      }

      if (verifyResult?.session) {
        await supabase.auth.setSession({
          access_token: verifyResult.session.access_token,
          refresh_token: verifyResult.session.refresh_token,
        });
        
        setUser(verifyResult.user);
        toast.success("Successfully authenticated with TON wallet");
      } else {
        throw new Error('Failed to authenticate with TON wallet');
      }
    } catch (error) {
      console.error("Error checking proof:", error);
      toast.error("Failed to authenticate with TON wallet");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function generatePayload() {
      tonConnectUI.setConnectRequestParameters({ state: "loading" });
      try {
        const payload = tonProofService.generatePayload();
        tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: { tonProof: payload },
        });
      } catch (error) {
        console.error("Error generating payload:", error);
        tonConnectUI.setConnectRequestParameters({ state: "ready" });
      }
    }

    const checkWalletConnection = async () => {
      const isConnected = tonConnectUI.connected;
      generatePayload();
      setIsWalletConnected(isConnected);
    };
    checkWalletConnection();

    const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
      if (wallet?.connectItems?.tonProof && "proof" in wallet.connectItems.tonProof) {
        checkProof(wallet, wallet.connectItems.tonProof);
      }

      setIsWalletConnected(!!wallet);
      if (!wallet) {
        setUser(null);
        setCookie('cabaltoken', '', { path: '/', maxAge: -1 });
        await supabase.auth.signOut();
      }
    });

    return unsubscribe;
  }, [setCookie, tonConnectUI]);

  const value = {
    isWalletConnected,
    userAddress: rawAddress,
    user,
    token: cookies.cabaltoken,
    isLoading
  };

  return (
    <WalletAuthContext.Provider value={value}>
      {children}
    </WalletAuthContext.Provider>
  );
}

export const useWalletAuth = () => {
  const context = useContext(WalletAuthContext);
  if (!context) {
    throw new Error('useWalletAuth must be used within a WalletAuthProvider');
  }
  return context;
};