import { useQuery } from "@tanstack/react-query";
import { supabase } from "../lib/supabase";
import RetroNotification from "../components/RetroNotification";

export const useCabalMembership = (walletAddress) => {
  const { data, isLoading } = useQuery({
    queryKey: ["cabalMembership", walletAddress],
    queryFn: async () => {
      if (!walletAddress) {
        return { isCabalMember: false, isCabalLeader: false };
      }

      try {
        // Check if wallet is a cabal leader
        const { data: leaderCheck, error: leaderError } = await supabase
          .rpc('is_cabal_leader', { p_address: walletAddress });

        if (leaderError) {
          console.error("Error checking leader status:", leaderError);
          throw leaderError;
        }

        // Check trader_profiles using raw_address
        const { data: profile, error: profileError } = await supabase
          .from("trader_profiles")
          .select("cabal_id, raw_address")
          .eq("raw_address", walletAddress.toUpperCase())
          .maybeSingle();

        if (profileError && profileError.code !== "PGRST116") {
          console.error("Error checking trader profile:", profileError);
          throw profileError;
        }

        return {
          isCabalMember: !!profile?.cabal_id,
          isCabalLeader: leaderCheck || false,
          cabalId: profile?.cabal_id || null
        };
      } catch (error) {
        console.error("Error in useCabalMembership:", error);
        return {
          isCabalMember: false,
          isCabalLeader: false,
          cabalId: null,
          error
        };
      }
    },
    enabled: !!walletAddress,
    retry: 1
  });

  if (isLoading) {
    return {
      isCabalMember: false,
      isCabalLeader: false,
      cabalId: null,
      isLoading,
      notification: <RetroNotification variant="info" title="LOADING">Checking cabal membership status...</RetroNotification>
    };
  }

  return {
    isCabalMember: data?.isCabalMember || false,
    isCabalLeader: data?.isCabalLeader || false,
    cabalId: data?.cabalId || null,
    isLoading,
    notification: data?.error ? (
      <RetroNotification variant="error" title="ERROR">
        Failed to check cabal membership
      </RetroNotification>
    ) : null
  };
};