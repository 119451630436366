import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { toast } from "sonner";
import { styled } from "styled-components";
import type { CabalData } from '@/types/cabal';

const FormContainer = styled.div`
  width: 100%;
  background-color: #191E1F;
  color: #D4D0D4;
  font-family: 'Press Start 2P', Consolas, monaco, monospace;
  font-size: 10px;
  line-height: 1.2;
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.44) 1px 1px;
  position: relative;
  overflow: hidden;
  margin-top: 1rem;
`;

const FormContent = styled.div`
  padding: 20px;
  border: 3px solid #454545;
  position: relative;
  box-shadow: rgba(116, 102, 90, 0.157) 4px 4px inset, rgba(0, 0, 0, 0.25) 8px 8px;
`;

const Description = styled.div`
  background-color: #454545;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: rgba(81, 81, 81, 0.25) 4px 4px;
`;

const FormInput = styled.input`
  width: 100%;
  background-color: #2C2D2E;
  border: 1px solid #454545;
  color: #D4D0D4;
  padding: 8px;
  margin-bottom: 8px;
  font-family: 'Press Start 2P', Consolas, monaco, monospace;
  font-size: 10px;
`;

const SubmitButton = styled.button`
  width: 100%;
  background-color: #191E1F;
  border: 3px solid #454545;
  color: #D4D0D4;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: rgba(81, 81, 81, 0.25) 4px 4px;
  font-family: 'Press Start 2P', Consolas, monaco, monospace;
  font-size: 10px;

  &:hover {
    background-image: linear-gradient(45deg, #191E1F, #2C2D2E);
    color: #00A0B8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

interface Props {
  cabalData: CabalData;
}

export const TelegramHandleForm: React.FC<Props> = ({ cabalData }) => {
  const [telegramHandle, setTelegramHandle] = useState('');

  const { mutate: updateTelegramHandle, isLoading } = useMutation({
    mutationFn: async (handle: string) => {
      if (!cabalData?.owner) {
        throw new Error('No cabal owner found');
      }

      const { error } = await supabase
        .from('teams')
        .update({ 
          telegram_handle: handle,
          updated_at: new Date().toISOString()
        })
        .eq('owner', cabalData.owner);

      if (error) throw error;
    },
    onSuccess: () => {
      toast.success('Telegram handle saved successfully');
      setTelegramHandle('');
    },
    onError: (error: Error) => {
      console.error('Error saving telegram handle:', error);
      if (error.message === 'No cabal owner found') {
        toast.error('Please connect your wallet to submit your Telegram handle');
      } else {
        toast.error('Failed to save telegram handle');
      }
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateTelegramHandle(telegramHandle);
  };

  return (
    <FormContainer>
      <FormContent>
        <Description>
          <p>
            Enter your Telegram to connect to the network. Your <span style={{ color: '#9BEDFF' }}>$IDENTITY</span> will be verified through the <span>unownqunownounownpunownyunowncunownvunowny</span> protocol. Act carefully, Hero...
          </p>
        </Description>
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            placeholder="Telegram handle"
            value={telegramHandle}
            onChange={(e) => setTelegramHandle(e.target.value)}
            disabled={isLoading || !cabalData?.owner}
          />
          <SubmitButton 
            type="submit" 
            disabled={isLoading || !cabalData?.owner || !telegramHandle.trim()}
          >
            {isLoading ? 'Saving...' : 'Submit'}
          </SubmitButton>
        </form>
      </FormContent>
    </FormContainer>
  );
};