import React from 'react';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Terminal, Users, Key } from "lucide-react";
import { toast } from "sonner";
import {
  RetroTable,
  RetroTableHeader,
  RetroTableBody,
  RetroTableRow,
  RetroTableHead,
  RetroTableCell,
} from "@/components/ui/retro-table";
import { TelegramHandleForm } from './cabal/TelegramHandleForm';

const CabalControlPanel = ({ cabalData }) => {
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copied to clipboard');
    } catch (err) {
      toast.error('Failed to copy');
    }
  };

  if (!cabalData?.owner) {
    return (
      <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-[#00A0B8] mb-4">
            Connect Your Wallet
          </h1>
          <p className="text-[#CBE9ED]">
            Please connect your wallet to access the Cabal Control Panel
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#121212] text-[#CBE9ED] font-mono p-4">
      <div className="max-w-7xl mx-auto">
        <header className="mb-8 border-b border-[#00A0B8] pb-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-[#00A0B8] flex items-center gap-2">
              <Terminal className="w-6 h-6" />
              CABAL_CONTROL_PANEL.exe
            </h1>
            <div className="text-sm">
              <span className="text-[#F02D3B]">LEADER_ADDRESS: </span>
              {cabalData?.owner?.slice(0, 6)}...{cabalData?.owner?.slice(-4)}
            </div>
          </div>
        </header>

        <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4 mb-6">
          <div className="flex items-center gap-2 mb-4">
            <Key className="w-4 h-4 text-[#00A0B8]" />
            <h2 className="text-[#00A0B8]">INVITE_SYSTEM.dat</h2>
          </div>
          <div className="flex items-center gap-4">
            <code className="bg-[#121212] p-2 rounded font-mono text-[#00A0B8]">
              {cabalData?.invite_code || 'No invite code available'}
            </code>
            {cabalData?.invite_code && (
              <div className="flex gap-2">
                <Button
                  onClick={() => copyToClipboard(cabalData.invite_code)}
                  className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
                >
                  Copy
                </Button>
                <Button
                  className="bg-[#1A1A1A] border border-[#00A0B8] text-[#00A0B8] hover:bg-[#002227]"
                >
                  Regenerate
                </Button>
                <Button
                  className="bg-[#1A1A1A] border border-red-500 text-red-500 hover:bg-red-900/20"
                >
                  Disable
                </Button>
              </div>
            )}
          </div>
        </Card>

        <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4 mb-6">
          <div className="flex items-center gap-2 mb-4">
            <Users className="w-4 h-4 text-[#00A0B8]" />
            <h2 className="text-[#00A0B8]">Submit Cabal Leader Telegram Handle to proceed with KYC</h2>
          </div>
          <TelegramHandleForm cabalData={cabalData} />
        </Card>

        <Card className="bg-[#1A1A1A] border-[#00A0B8] p-4">
          <div className="flex items-center gap-2 mb-4">
            <Users className="w-4 h-4 text-[#00A0B8]" />
            <h2 className="text-[#00A0B8]">TEAM_MEMBERS.dat</h2>
          </div>
          <RetroTable>
            <RetroTableHeader>
              <RetroTableRow>
                <RetroTableHead>#</RetroTableHead>
                <RetroTableHead>Wallet Address</RetroTableHead>
                <RetroTableHead>Raw Address</RetroTableHead>
                <RetroTableHead>Nickname</RetroTableHead>
              </RetroTableRow>
            </RetroTableHeader>
            <RetroTableBody>
              {cabalData?.team_members?.map((member, index) => (
                <RetroTableRow key={index}>
                  <RetroTableCell>{index + 1}</RetroTableCell>
                  <RetroTableCell className="font-mono">
                    {member.address?.slice(0, 6)}...{member.address?.slice(-4)}
                  </RetroTableCell>
                  <RetroTableCell className="font-mono text-[#9BEDFF]">
                    {member.raw_address ? `${member.raw_address.slice(0, 6)}...${member.raw_address.slice(-4)}` : 'N/A'}
                  </RetroTableCell>
                  <RetroTableCell>
                    {member.nickname || 'N/A'}
                  </RetroTableCell>
                </RetroTableRow>
              ))}
            </RetroTableBody>
          </RetroTable>
        </Card>
      </div>
    </div>
  );
};

export default CabalControlPanel;